/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons"

dayjs.extend(relativeTime)

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 150, height: 150, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
            github
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="bio-avatar"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      {author?.name && (
        <div style={{ verticalAlign: "middle" }}>
          <p>
            <strong>{dayjs().diff("1993-12-19", "year")}</strong> years old,{" "}
            <strong>Software Engineer</strong>, <strong>Gamer</strong>
            <br></br>Read tons of mangas
            <br></br>YNWA
            <br></br>
            <br></br>
            <a href={`https://twitter.com/${social?.twitter || ``}`}>
              <FontAwesomeIcon
                color={"#1da1f2"}
                size={"lg"}
                icon={faTwitter}
              ></FontAwesomeIcon>
            </a>{" "}
            <a href={`https://github.com/${social?.github || ``}`}>
              <FontAwesomeIcon
                color={"#24292e"}
                size={"lg"}
                icon={faGithub}
              ></FontAwesomeIcon>
            </a>
          </p>
        </div>
      )}
    </div>
  )
}

export default Bio
